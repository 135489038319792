@font-face {
    font-family: 'James Fajardo';
    src: url('/fonts/James_Fajardo.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'Skinny Jeans';
    src: url('/fonts/skinny_jeans.ttf');
    font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* React Select */
.react-select__control {
    @apply border h-10 w-full text-gray-500 border-pink-500 flex flex-row flex-wrap text-sm cursor-pointer;
    font-size: 16px !important;
}
.dark-input .react-select__control {
    @apply border-gray-500 text-white bg-white;
}
.error-input .react-select__control {
    @apply border-red-500 !important;
}
.react-select__indicator-separator {
    @apply hidden;
}
.react-select__menu {
    @apply m-0 rounded !important;
}
.react-select__menu-list {
    @apply bg-gray-100 rounded;
}

/* Checkbox */
input:checked + div svg {
    @apply block !important;
}

/* Timepicker */
.timepicker-popup .ant-picker-panel {
    width: 100%;
    height: 100%;
}
.timepicker-popup .ant-picker-time-panel .ant-picker-content {
    @apply h-[300px];
}

/* Timepicker & Datepicker */
.ant-picker-input > input {
    @apply cursor-pointer !important;
    font-size: 16px !important;
}
