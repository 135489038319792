@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@primary-color: #1E368E;
@border-color-base: #DD6ABA;
// @input-padding-horizontal: 0.5rem;
// @input-padding-vertical: 0.25rem;

.ant-picker-input > input {
    padding: 0.25rem 0.5rem !important;
}
